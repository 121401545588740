import {parse} from 'query-string';
import {clearApiHostNationCondition} from '@/helper/localized-site';
import {env} from '@/helper/env';
import {mockUtil} from '@/helper/moc';

const DEV_HOST = 'dev.cmexpro.com';
// const DEV_HOST = 'dev2.cmexpro.com';
// const DEV_HOST = 'dev2.cmexpro.com';
// const DEV_HOST = 'dev3.cmexpro.com';
// const DEV_HOST = 'fat.phemex.com';
// const DEV_HOST = 'fat3.phemex.com';
// const DEV_HOST = 'fat3.phemex.com';
// const DEV_HOST = 'ea.phemex.com';
// const DEV_HOST = 'phemex.com';

const mockPrefix = mockUtil.getMockPrefix();
const api = genHost('https://api10') + mockPrefix;
const ws = genHost(`wss://ws10`) + mockPrefix;

export const SERVICES = {
  API: api,
  WS: ws,
  STATIC_URL: 'https://static.phemex.com',
  BASE_URL: genBaseUrl(),
  TEST_NET_URL: genTestNetUrl(),
  IMG_URL: 'https://img.phemex.com',
};

function genBaseUrl() {
  if (process.browser) {
    return location.origin;
  }

  return 'https://phemex.com';
}

function genTestNetUrl() {
  if (env.isTestNet) {
    return location.origin;
  }

  return 'https://testnet.phemex.com';
}

function genHost(prefix) {
  const calcSeparator = (hostname) => (hostname.split('.').length === 2 ? '.' : '-');
  const concatHost = (prefix, host) => [prefix, host].join(calcSeparator(host));

  // 服务端返回正式环境地址
  if (env.isServer) {
    return concatHost(prefix, 'phemex.com');
  }

  if (env.isLocal) {
    // home 项目依靠本地变量来代理
    if (['4000', '9000'].includes(location.port)) {
      return concatHost(prefix, DEV_HOST);
    }
    // 页面被 trade 项目本地加载时使用：依靠 url 参数来代理
    const env = parse(window.location.search)['proxyEnv'];
    const tradeLoginMap = {
      dev: 'dev.cmexpro.com',
      dev2: 'dev2.cmexpro.com',
      dev3: 'dev3.cmexpro.com',
      fat: 'fat.phemex.com',
      fat2: 'fat2.phemex.com',
      fat3: 'fat3.phemex.com',
      ea: 'ea.phemex.com',
    };
    const host = tradeLoginMap[env] || 'phemex.com';
    return concatHost(prefix, host);
  }

  // 线上
  return concatHost(prefix, clearApiHostNationCondition(location.hostname));
}

export function getCurrentEnvironment() {
  const hostSegments = location.host.split('.');
  return hostSegments.length === 2 ? 'ea' : hostSegments[0];
}
