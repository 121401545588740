import {envUtil} from '@/helper/env';

/**
 * mock 相关业务中应该只使用 mockUtil
 */
export const mockUtil = {
  MOCK_URL_FLAG: '/moc/',
  _isMock: false,
  /**
   * 要求在应用最开始进行模拟状态初始化
   *
   * 整个前端应用运行过程中，mock 状态不允许发生变动
   */
  initMockStatus() {
    if (!envUtil.isServer) {
      mockUtil._isMock = location.pathname.includes(mockUtil.MOCK_URL_FLAG);
    }
    mockUtil._isMock && console.warn('[MOCK]');
  },
  /**
   * 判断模拟状态的唯一方法
   */
  isMock() {
    return mockUtil._isMock;
  },
  getMockPrefix() {
    return mockUtil.isMock() ? '/moc' : '';
  },
  gotoLive(url?: string) {
    url = url || '/';
    if (mockUtil.isMockUrl(url)) {
      throw new Error('wrong live url');
    }
    window.location.assign(url);
  },
  gotoMock(url?: string) {
    if (!mockUtil.isMockUrl(url)) {
      throw new Error('wrong mock url!');
    }
    window.location.assign(url);
  },
  currentLiveUrl() {
    return location.href.replace(mockUtil.MOCK_URL_FLAG, '/');
  },
  isMockUrl(url: string) {
    return url.includes(mockUtil.MOCK_URL_FLAG);
  },
};

mockUtil.initMockStatus();
