export const langUtil = {
  genLG(rawLangData) {
    const langData = convert(rawLangData || {});
    return function (lang, params) {
      if (!lang) {
        // console.warn('lang is empty');
        return '';
      }

      const {id, en} = lang;
      const result = langData[id] || en;
      if (params) {
        // return id + ' ' + format(result, params);
        return format(result, params);
      }

      // return id + ' ' + result;
      return result;
    };
  },
  isLanguage(segment) {
    return ['en', 'ru', 'ko', 'ja', 'es', 'cn', 'de', 'fr', 'tr', 'pt', 'vi'].indexOf(segment) > -1;
  },
  parsePathname(pathname) {
    const segments = pathname.split('/').filter(Boolean);
    if (segments.length < 1) {
      return {lang: 'en', pathname: '/'};
    }

    const segment = segments[0];
    if (langUtil.isLanguage(segment)) {
      return {lang: segment, pathname: '/' + segments.slice(1).join('/')};
    }
    return {lang: 'en', pathname: '/' + segments.join('/')};
  },
};

function convert(obj) {
  const result = {};
  Object.keys(obj).forEach((k) => {
    const ids = obj[k];
    ids.forEach((id) => (result[id] = k));
  });
  return result;
}

function format(template, args) {
  return Object.keys(args).reduce((p, c) => p.replace(new RegExp(['(\\{', c, '\\})'].join(''), 'g'), args[c]), template);
}
