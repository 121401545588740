import {SLUGS} from '@px/const/slugs';
import {appendTemplates} from '@px/helper/template';

export const templates: Template[] = [
  {
    slug: SLUGS.HOME,
    loader: () => import('px-home/src/modules/homepage'),
  },
  {
    slug: SLUGS.MARKETS,
    loader: () => import('px-home/src/modules/markets'),
  },
  {
    slug: SLUGS.LOGIN,
    loader: () => import('px-home/src/modules/login'),
  },
  {
    slug: SLUGS.REGISTER,
    loader: () => import('px-home/src/modules/register'),
  },
  {
    slug: SLUGS.RESET_PASSWORD,
    loader: () => import('px-home/src/modules/reset-password'),
  },
  {
    slug: SLUGS.RESET_SECURITY,
    loader: () => import('px-home/src/modules/reset-security'),
  },
  {
    slug: SLUGS.OAUTH_AUTHORIZE,
    loader: () => import('px-home/src/modules/oauth-authorize'),
  },
  {
    slug: SLUGS.OAUTH_AUTHORIZE_LOGIN,
    loader: () => import('px-home/src/modules/oauth-authorize/login'),
  },
  {
    slug: SLUGS.REDIRECT,
    loader: () => import('px-home/src/modules/redirect'),
  },
  {
    slug: SLUGS.TELEGRAM_APP_AUTH,
    loader: () => import('px-home/src/modules/components/access-telegram/AppAccessWithTelegram'),
  },
];

appendTemplates(templates);
