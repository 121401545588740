import Big from 'big.js';
Big.NE = -9;

import 'px-home/src/helper/pollyfill/Map';
import 'px-home/src/helper/pollyfill/rxjs';
import {resolveAllTemplates} from '@px/helper/template';
import {syncRouter} from '@px/router/sync';
import {url$, updateUrl} from '@px/streams/url';
import {wwData$} from 'px-home/src/streams/worker-data';
import {genLG} from './lang';

declare global {
  interface Window {
    __LG__: (locale: Locale, params: Record<string, any>) => string;
  }
}

export async function main(hydrate, createDataWorker: () => Worker) {
  // @ts-ignore
  window.process = {browser: true};
  syncRouter(updateUrl);
  wwData$.init(createDataWorker);
  let currentPage = null;
  url$.subscribe(async (url: Url) => {
    if (currentPage) {
      currentPage.$destroy();
      window.scroll({top: 0});
    }
    const templates = resolveAllTemplates();
    const template = templates.find((template) => template.slug === url.slug);
    if (!template) {
      console.warn(`template not found`);
      return;
    }
    const LG = genLG(url.locale);
    // 用于兼容 popupUtil
    window.__LG__ = LG;
    const context = new Map([['LG', LG]]);

    const {component} = await template.loader();
    const target = document.getElementById('page');
    currentPage = new component({target, props: {}, hydrate, context});
  });

  // 替 tingyun 兜底，清理错误配置，运行几个月级别时间后可以删除
  if ((localStorage.getItem('TINGYUN_STORAGE') || '').includes('apm2')) {
    localStorage.removeItem('TINGYUN_STORAGE');
  }
}
