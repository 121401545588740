import {Subject} from 'rxjs';

declare global {
  interface Window {
    createDataWorker(): Worker;
  }
  interface Worker {
    subscribe(event: string, callback: Function): Worker;
  }
}

export class WebWorkerSubject<Get, Send> extends Subject<Get> {
  worker: Worker;

  init(createDataWorker: () => Worker) {
    this.worker = createDataWorker();
    this.worker.subscribe('message', ({data}: {data: Get}) => {
      if (this.closed) {
        throw new Error();
      }
      if (!this.isStopped) {
        const {observers} = this;
        const len = observers.length;
        const copy = observers.slice();
        for (let i = 0; i < len; i++) {
          copy[i].next(data);
        }
      }
    });
  }

  send(message: Send) {
    this.worker && this.worker.postMessage(message);
  }

  /**
   * @deprecated
   * 建议使用 send 以获得更好的类型提示，使用方法完全一致
   */
  next(message) {
    this.worker && this.worker.postMessage(message);
  }
}
