const showLog = process.browser && localStorage.getItem('ty_show_log') === 'Y';

export const ty = {
  setUid(userId) {
    const tingyun = window.TINGYUN;
    if (tingyun && tingyun.setUid) {
      showLog && console.log('[TY] SetUid:', userId);
      tingyun.setUid(userId);
    }
  },
  createAutoEvent(options) {
    const tingyun = window.TINGYUN;
    if (tingyun && tingyun.createAutoEvent) {
      showLog && console.log('[TY] AutoEvent:', options);
      return tingyun.createAutoEvent(options);
    }
    return {
      end() {},
      fail() {},
    };
  },
  createEvent(options) {
    if (document.visibilityState !== 'visible') {
      showLog && console.log('[TY] ignore NewAction:', options);
      return;
    }

    const tingyun = window.TINGYUN;
    if (tingyun && tingyun.newAction) {
      showLog && console.log('[TY] NewAction:', options);
      tingyun.newAction(options, true);
    }
  },
};
