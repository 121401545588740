import {env} from '@px/env';

declare global {
  interface Window {
    langDic: Record<string, string>;
  }
}

/**
 * 兼容老版本 通过 svelte context 获取
 * @param locale
 */
export function genLG(locale: Locale) {
  const langData = env.isServer ? {} : window.langDic;
  return function (lang, params) {
    if (!lang) {
      console.warn('lang is empty');
      return '';
    }

    // production 模式下 lang 是 id
    const result = typeof lang === 'string' ? langData[lang] : lang[locale] || lang['en'];
    if (params) {
      return format(result, params);
    }

    return result;
  };
}

function format(template, args) {
  return Object.keys(args).reduce((p, c) => p.replace(new RegExp(['(\\{', c, '\\})'].join(''), 'g'), args[c]), template);
}
