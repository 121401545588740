import {BehaviorSubject} from 'rxjs';
import {parse} from 'query-string';
import {env} from '@px/env';
import {langUtil} from 'px-home/src/helper/lang';

declare let SSR_LOCALE: Locale;

const _url$: BehaviorSubject<Url> | any = new BehaviorSubject<Url>(createInitUrl());
export const url$ = _url$.asObservable();

export function updateUrl({pathname, search, key, action, href}) {
  _url$.next(formatUrl({pathname, search, key, action, href}));
}

function formatUrl({pathname, search, key, action, href}): Url {
  const query = parse(search) as UrlQuery;
  const {lang: locale, pathname: slug} = langUtil.parsePathname(pathname);
  return {action, locale, slug, pathname, query, key, href};
}

function createInitUrl(): Url {
  if (env.isServer) {
    const locale = typeof SSR_LOCALE === 'string' ? SSR_LOCALE : 'en';
    return {action: 'INIT', slug: '', locale, pathname: '', query: {}, key: 'default', href: ''};
  }

  const {pathname, search, href} = window.location;
  return formatUrl({pathname, search, href, key: 'default', action: 'INIT'});
}
