import {filter} from 'rxjs/operators';
import {SERVICES} from '@/const/services';
import {envUtil} from '@/helper/env';
import {mockUtil} from '@/helper/moc';
import {ty} from '@/da/tingyun';
import {WebWorkerSubject} from '@/observable/WebWorkerSubject';
import type {ToUiMsg, FromUiMsg, UTingYun} from '@px/ws-data';

export const wwData$ = new WebWorkerSubject<ToUiMsg, FromUiMsg>();

if (!envUtil.isBot) {
  wwData$.pipe(filter((o) => o.type === 'worker-ready')).subscribe(() => {
    wwData$.send({type: 'ws-url', url: SERVICES.WS});
    // TODO 临时解决方案 初始化时往worker发送初始数据，否则程序不执行，将无法获得行情信息；后续可以考虑降低worker对state-symbol的依赖
    wwData$.send({type: 'state-symbol', tradeType: 'Perpetual', symbol: mockUtil.isMock() ? 'BTCMUSDT' : 'EOSUSD'} as FromUiMsg);
  });
}

// 接收 worker 中的听云事件
wwData$.pipe(filter((o: ToUiMsg) => o.type === 'tingyun-event')).subscribe((o: UTingYun) => ty.createEvent(o.data));
