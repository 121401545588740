import {main} from './app-client';
import 'px-home/src/helper/pollyfill/Array';
import 'px-home/src/helper/pollyfill/EventTarget';
import 'px-home/src/helper/pollyfill/Map';
import 'px-home/src/helper/pollyfill/rxjs';
import 'dynamic-templates-package';

declare const WORKER_URL: string;

main(true, () => new Worker(WORKER_URL)).then();
