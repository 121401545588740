Map.prototype.toObject = function(convert = (o) => o) {
  return Array.from(this.entries()).reduce((p, [key, value]) => {
    p[key] = convert(value);
    return p;
  }, {});
};

Map.prototype.toArray = function(convert = (o) => o) {
  return Array.from(this.values(), convert);
};
