export const enum SLUGS {
  HOME = '/',
  CRYPTO_TRADING_COMPETITION_1_ON_1 = '/1-on-1-crypto-trading-competition',
  NOT_FOUND = '/404',
  ABOUT = '/about',
  ABOUT_AMBASSADOR_KEVIN_DE_BRUYNE = '/about/ambassador/kevin-de-bruyne',
  ABOUT_AMBASSADOR_KEVIN_DE_BRUYNE_AUDIT = '/about/ambassador/kevin-de-bruyne-audit',
  ABOUT_BRAND_STYLE_GUIDE = '/about/brand-style-guide',
  ACCOUNT_FEEDBACK = '/account/feedback',
  ACCOUNT_OVERVIEW = '/account/overview',
  ACCOUNT_PREMIUM_CENTER = '/account/points/my-privileges',
  ACCOUNT_SECURITY = '/account/security',
  ACCOUNT_SECURITY_PASSKEY = '/account/security/passkeys',
  ACCOUNT_VERIFICATION = '/account/verification',
  ACCOUNT_API_MANAGEMENT = '/account/api-management',
  ACCOUNT_TAX_REPORTING = '/account/tax-reporting',
  ACCOUNT_SUB_ACCOUNTS = '/account/sub-accounts',
  ACCOUNT_INVITE_FRIENDS = '/account/referral/invite-friends',
  ACCOUNT_INVITE_FRIENDS_ENTRY = '/account/referral/invite-friends-entry',
  ACCOUNT_SETTING = '/account/setting',
  ACCOUNT_SECURITY_AUTHORIZATION = '/account/security/authorization',
  ACCOUNT_INVITE_FRIENDS_MY_INVITATIONS = '/account/referral/invite-friends/my-invitations',
  SHARE_PREMIUM = '/account/referral/share-premium',
  ACCOUNT_SECURITY_DEVICE_MANAGE = '/account/security/device',
  ACCOUNT_SECURITY_DEVICE_HISTORY = '/account/security/device/history',
  ACCOUNT_SECURITY_WITHDRAW_LIMIT = '/account/security/withdraw-limit',

  KOL_LANDING = '/activity/all-star',
  KOL_LANDING_ACTIVITY = '/activity/all-star/kol',
  CRYPTO_COIN_FLIP = '/activity/crypto-coin-flip',
  CRYPTO_TRADING_REBATES = '/activity/crypto-trading-rebates',
  EXCLUSIVE_COUPONS = '/activity/exclusive-coupons',
  KOL_LANDING_PAGE = '/activity/kol',
  KYC_MYSTERY_BOX = '/activity/mystery-box',
  PREDICT_ETH_PRICE = '/activity/predict-eth-price',
  ACTIVITY_REGISTER_REWARDS = '/activity/register-rewards',
  SWAP_ETH = '/activity/swap-eth-to-ethw-eths',
  SWAP_ETH_HISTORY = '/activity/swap-eth-to-ethw-eths/history',
  ALL_STAR_PROGRAM = '/affiliate-program',
  ALL_STAR_PROGRAM_BAK = '/all-star-program',
  ASSETS_HISTORY = '/assets/contract-history',
  ASSETS_ORDER_HISTORY = '/assets/contract-order-history',
  ASSETS_PNL_DETAILS = '/assets/contract-pnl-details',
  ASSETS_TRADE_HISTORY = '/assets/contract-trade-history',
  ASSETS_CONVERT_HISTORY = '/assets/conversion-history',
  ASSETS_CONVERT_FAQ = '/assets/convert-faq',
  ASSETS_TRANSFER = '/assets/cross-platform-transfer',
  ASSETS_DEPOSIT = '/assets/deposit',
  ASSETS_DEPOSIT_FAQ = '/assets/deposit/faq',
  ASSETS_DETAILS = '/assets/details',
  ASSETS_FUNDS = '/assets/funds',
  ASSETS_MARGIN_HISTORY = '/assets/margin-history',
  ASSETS_MARGIN_TRADE_BORROW_HISTORY = '/assets/margin-trade-borrow-history',
  ASSETS_MARGIN_TRADE_INTEREST_HISTORY = '/assets/margin-trade-interest-history',
  ASSETS_MARGIN_TRADE_ORDER_HISTORY = '/assets/margin-trade-order-history',
  ASSETS_MARGIN_TRADE_REPAY_HISTORY = '/assets/margin-trade-repay-history',
  ASSETS_MARGIN_TRADE_TRADE_HISTORY = '/assets/margin-trade-trade-history',
  ASSETS_OVERVIEW = '/assets/overview',
  ASSETS_SPOT_ACCOUNT = '/assets/spot-account',
  ASSETS_CONTRACT_ACCOUNT = '/assets/contract-account',
  ASSETS_MARGIN_ACCOUNT = '/assets/margin-account',
  ASSETS_INVESTMENT_ACCOUNT = '/assets/investment-account',
  ASSETS_SPOT_ORDER = '/assets/spot-order-history',
  ASSETS_SPOT_HISTORY = '/assets/spot-history',
  ASSETS_SPOT_PNL_DETAILS = '/assets/spot-pnl-history',
  ASSETS_SPOT_TRADE = '/assets/spot-trade-history',
  ASSETS_WITHDRAWAL = '/assets/withdrawal',
  ASSETS_WITHDRAWAL_FAQ = '/assets/withdrawal/faq',
  ASSETS_FIAT_ACCOUNT = '/assets/fiat-account',
  ASSETS_WITHDRAWAL_ADDRESS = '/assets/withdrawal-address',
  ASSETS_WITHDRAWAL_CONFIRM = '/assets/withdrawal-confirm',
  BUY_CRYPTO_HISTORY = '/assets/buy-crypto-history',
  ASSETS_BUY_SELL_CRYPTO_HISTORY = '/assets/fiat-buy-sell-crypto-history',
  ASSETS_FIAT_DEPOSIT = '/assets/fiat-deposit',
  ASSETS_FIAT_WITHDRAW = '/assets/fiat-withdraw',
  ASSETS_TRADING_ACCOUNT = '/assets/trading-account',
  ASSETS_FUND_ACCOUNT = '/assets/fund-account',
  ASSETS_BORROWING_DETAILS = '/assets/borrowing-details',
  ASSETS_ORDER_HISTORY_GROUP = '/assets/order-history',
  ASSETS_DEPOSIT_WITHDRAW_HISTORY = '/assets/deposit-withdraw-history',
  ASSETS_TRANSFER_CONVERT_HISTORY = '/assets/transfer-convert-history',
  ASSETS_ACCOUNT_UPGRADE_H5 = '/assets/account-upgrade',
  ASSETS_LENDING_ACCOUNT = '/assets/lending-account',
  ASSETS_LENDING_HISTORY = '/assets/lending-history',
  BONUS = '/rewards-hub',
  BONUS_CENTER = '/bonus-center',
  BUY_CRYPTO = '/buy-crypto',
  BUY_CRYPTO_BUY_ALGO = '/buy-crypto/buy-algorand-algo',
  BUY_CRYPTO_BUY_AVAX = '/buy-crypto/buy-avax-avalanche',
  BUY_CRYPTO_BUY_BTC = '/buy-crypto/buy-bitcoin',
  BUY_CRYPTO_BUY_BCH = '/buy-crypto/buy-bitcoin-cash-bch',
  BUY_CRYPTO_BUY_ADA = '/buy-crypto/buy-cardano-ada',
  BUY_CRYPTO_BUY_MANA = '/buy-crypto/buy-decentraland-mana',
  BUY_CRYPTO_BUY_DENT = '/buy-crypto/buy-dent',
  BUY_CRYPTO_BUY_DOGE = '/buy-crypto/buy-dogecoin',
  BUY_CRYPTO_BUY_ETHEREUM = '/buy-crypto/buy-ethereum',
  BUY_CRYPTO_BUY_LTC = '/buy-crypto/buy-litecoin-ltc',
  BUY_CRYPTO_BUY_LRC = '/buy-crypto/buy-loopring-lrc',
  BUY_CRYPTO_BUY_MATIC = '/buy-crypto/buy-matic-polygon',
  BUY_CRYPTO_BUY_DOT = '/buy-crypto/buy-polkadot-dot',
  BUY_CRYPTO_BUY_SAND = '/buy-crypto/buy-sandbox-crypto-sand',
  BUY_CRYPTO_BUY_SHIB = '/buy-crypto/buy-shiba-inu-coin-shib',
  BUY_CRYPTO_BUY_SOL = '/buy-crypto/buy-solana-sol',
  BUY_CRYPTO_BUY_XTZ = '/buy-crypto/buy-tezos-xtz',
  BUY_CRYPTO_BUY_TRX = '/buy-crypto/buy-tron-trx',
  BUY_CRYPTO_BUY_USDC = '/buy-crypto/buy-usdc',
  BUY_CRYPTO_BUY_XLM = '/buy-crypto/buy-xlm-stellar-lumens',
  BUY_CRYPTO_BUY_XRP = '/buy-crypto/buy-xrp-ripple',
  BUY_CRYPTO_BUY_ZEC = '/buy-crypto/buy-zcash-zec',
  BUY_CRYPTO_FAQ = '/buy-crypto/faq',
  P2P_HOME = '/buy-crypto/fiat',
  P2P_MAKER_HOME = '/buy-crypto/fiat/advertiser',
  P2P_ADVERTISER_REVIEW = '/buy-crypto/fiat/advertiser/leave-a-review',
  P2P_CREATE_AN_AD = '/buy-crypto/fiat/create-an-ad',
  P2P_FAQ = '/buy-crypto/fiat/faq',
  P2P_MY_ADS = '/buy-crypto/fiat/my-ads',
  P2P_ADS_DETAILS = '/buy-crypto/fiat/my-ads/details',
  P2P_ADS_EDIT = '/buy-crypto/fiat/my-ads/edit',
  P2P_ORDERS = '/buy-crypto/fiat/orders',
  P2P_HOME_MARKET = '/buy-crypto/fiat/p2p-markets',
  P2P_MERCHANT_APPLY = '/buy-crypto/fiat/p2p-merchant-apply',
  ASSETS_PAYMENT_METHODS = '/buy-crypto/fiat/payment-methods',
  P2P_HOME_LOGIN = '/buy-crypto/fiat/place-order',
  P2P_APPEAL_AN_ORDER = '/buy-crypto/fiat/place-order/appeal-an-order',
  P2P_ORDER_DETAILS = '/buy-crypto/fiat/place-order/details',
  P2P_USER_CENTER = '/buy-crypto/fiat/user-center',
  P2P_USER_REVIEW = '/buy-crypto/fiat/user-center/review',
  BUY_CRYPTO_HOW_TO_BUY = '/buy-crypto/how-to-buy-bitcoin',
  BUY_CRYPTO_PLACE_ORDER = '/buy-crypto/place-order',
  BUY_CRYPTO_PLACE_ORDER_HISTORY = '/buy-crypto/place-order/history',
  BUY_CRYPTO_CARD_HISTORY = '/buy-crypto/card/history',
  BUY_CRYPTO_CARD_ORDER_CONFIRM = '/buy-crypto/card/order-confirm',
  BUY_CRYPTO_CARD_ORDER_FAIL = '/buy-crypto/card/order-fail',
  BUY_CRYPTO_CARD_ORDER_SUCCESS = '/buy-crypto/card/order-success',
  BUY_CRYPTO_CARD_PAY = '/buy-crypto/card/pay',
  BUY_CRYPTO_CARD_PAYMENT = '/buy-crypto/card/payment',
  BUY_CRYPTO_CARD_PLACE_ORDER = '/buy-crypto/card/place-order',
  BUY_CRYPTO_CARD_DETAILS = '/buy-crypto/card/card-details',
  BUY_CRYPTO_CARD_SELL_CONFIRM = '/buy-crypto/card/sell-confirm',
  BUY_CRYPTO_CARD_SELL_RESULT = '/buy-crypto/card/sell-result',
  CONTRACT_DATA_INSIGHT = '/contract/data-insight',
  CONTRACT_FUNDING_HISTORY = '/contract/funding-history',
  CONTRACT_INDEX_PRICE = '/contract/index-price',
  CONTRACT_INSURANCE_FUND = '/contract/insurance-fund',
  CONTRACT_INTRODUCTION = '/contract/introduction',
  CONTRACT_MARK_PRICE = '/contract/mark-price',
  CONTRACT_PERPETUAL_CONTRACT = '/contract/perpetual-contract',
  CONTRACT_LEVERAGE_MARGIN = '/contract/leverage-margin',
  BECOME_A_TRADER = '/copy-trading/become-a-trader',
  COPY_TRADING_DRAW = '/copy-trading/draw',
  COPY_SETTINGS = '/copy-trading/follower-view/copy-setting',
  COPIER_DETAIL = '/copy-trading/follower-view/details',
  TRADER_HOME_FOLLOWER_VIEW = '/copy-trading/follower-view/home',
  COPY_TRADING_FAQ = '/copy-trading/home/faq',
  COPY_LEADERBOARD = '/copy-trading/leaderboard',
  COPY_TRADING_LIST = '/copy-trading/list',
  TRADER_HOME = '/copy-trading/trader-view/home',
  COPY_TRADING_TRADER_SETTING = '/copy-trading/trader-view/setting',
  GMX_TRADER_LIST = '/copy-trading/gmx-trader-list',
  CONTRACT_WARS = '/crypto-trading-competition',
  BITSHIN_TRADE_COMPETITION = '/crypto-trading-competition/bitshin',
  WORLD_CUP = '/crypto-trading-competition/football-frenzy',
  MO_TRADE_COMPETITION = '/crypto-trading-competition/mo-diseax',
  PT_ARENA_5 = '/crypto-trading-competition/pt-arena-5',
  MARGIN_TRADING_COMPETITION = '/crypto-trading-competition/that-martini-guy',
  DOWNLOAD = '/download',
  DOWNLOAD_IOS_PRO = '/download/ios-pro',
  EARN_HOME = '/earn-crypto',
  EARN_SAVINGS_ACCOUNT = '/earn-crypto/savings-account',
  EARN_MY_EARNINGS = '/earn-crypto/my-earnings',
  EARN_LAUNCHPOOL = '/earn-crypto/launchpool',
  EARN_LIMITED_TIME_OFFER = '/earn-crypto/limited-time-offer',
  EARN_PREDICT_TO_EARN = '/crypto-prediction-market',
  EARN_PREDICT_TO_EARN_DETAIL = '/crypto-prediction-market/detail',
  EARN_PREDICT_TO_EARN_HISTORY = '/crypto-prediction-market/history',
  EARN_CRYPTO_CONVERTER = '/crypto-converter',
  EARN_LAUNCHPAD = '/crypto-launchpad',
  EARN_LAUNCHPAD_DETAIL = '/crypto-launchpad/detail',
  EARN_LAUNCHPAD_HISTORY = '/crypto-launchpad/history',
  EVENT_SUBSCRIPTION = '/event/subscription',
  FEES_CONDITIONS = '/fees-conditions',
  BIG_CLIENT_DASHBOARD = '/institutional-account-dashboard',
  INSTITUTIONAL_CLIENT = '/institutional-clients',
  INVESTMENT = '/investment',
  KOL_HOME = '/kol/home',
  KOL_GENERALIZE = '/kol/generalize',
  KOL_MATERIAL = '/kol/material',
  KOL_COMMISSION = '/kol/commission',
  KOL_ACCOUNTS = '/kol/accounts',
  KOL_SUB_AGENT = '/kol/sub-agent',
  KOL_WITHDRAW = '/kol/withdraw',
  KOL_EQUITY_DETAILS = '/kol/equity-details',
  KOL_PERSONAL = '/kol/personal',
  KOL_AGENT_DEAL = '/kol/agent-deal',
  KOL_PREMIUM = '/kol/premium',
  LEARN_CRYPTO = '/learn-crypto',
  LEARN_CRYPTO_CRYPTO_DERIVATIVES = '/learn-crypto/crypto-derivatives',
  LEARN_CRYPTO_CRYPTO_DERIVATIVES_DERIVATIVES = '/learn-crypto/crypto-derivatives/derivatives',
  LEARN_CRYPTO_CRYPTO_DERIVATIVES_LEVERAGE_LIQUIDATION = '/learn-crypto/crypto-derivatives/leverage-liquidation',
  LEARN_CRYPTO_CRYPTO_DERIVATIVES_MARGIN = '/learn-crypto/crypto-derivatives/margin',
  LEARN_CRYPTO_CRYPTO_DERIVATIVES_PERPETUAL_CONTRACTS = '/learn-crypto/crypto-derivatives/perpetual-contracts',
  LEARN_CRYPTO_CRYPTO_DERIVATIVES_QUIZ = '/learn-crypto/crypto-derivatives/quiz',
  LEARN_CRYPTO_CRYPTO_FIAT = '/learn-crypto/crypto-fiat',
  LEARN_CRYPTO_CRYPTO_FIAT_HOW_DOES_CRYPTO_WORK = '/learn-crypto/crypto-fiat/how-does-crypto-work',
  LEARN_CRYPTO_CRYPTO_FIAT_QUIZ = '/learn-crypto/crypto-fiat/quiz',
  LEARN_CRYPTO_CRYPTO_FIAT_WHAT_IS_CRYPTO = '/learn-crypto/crypto-fiat/what-is-crypto',
  LEARN_CRYPTO_CRYPTO_FIAT_WHAT_IS_FIAT = '/learn-crypto/crypto-fiat/what-is-fiat',
  LEARN_CRYPTO_HOW_TO_BUY_CRYPTO = '/learn-crypto/how-to-buy-crypto',
  LEARN_CRYPTO_HOW_TO_BUY_CRYPTO_CREDIT_CARD = '/learn-crypto/how-to-buy-crypto/credit-card',
  LEARN_CRYPTO_HOW_TO_BUY_CRYPTO_OTC_ACTIVATE = '/learn-crypto/how-to-buy-crypto/otc-activate',
  LEARN_CRYPTO_HOW_TO_BUY_CRYPTO_OTC_DEPOSIT = '/learn-crypto/how-to-buy-crypto/otc-deposit',
  LEARN_CRYPTO_HOW_TO_BUY_CRYPTO_OTC_PURCHASE = '/learn-crypto/how-to-buy-crypto/otc-purchase',
  LEARN_CRYPTO_HOW_TO_BUY_CRYPTO_QUIZ = '/learn-crypto/how-to-buy-crypto/quiz',
  LEARN_CRYPTO_HOW_TO_BUY_CRYPTO_TRANSFER = '/learn-crypto/how-to-buy-crypto/transfer',
  LEARN_CRYPTO_HOW_TO_EARN_CRYPTO = '/learn-crypto/how-to-earn-crypto',
  LEARN_CRYPTO_HOW_TO_EARN_CRYPTO_PHEMEX_LAUNCHPAD = '/learn-crypto/how-to-earn-crypto/phemex-launchpad',
  LEARN_CRYPTO_HOW_TO_EARN_CRYPTO_PHEMEX_LAUNCHPOOL = '/learn-crypto/how-to-earn-crypto/phemex-launchpool',
  LEARN_CRYPTO_HOW_TO_EARN_CRYPTO_PHEMEX_SAVINGS = '/learn-crypto/how-to-earn-crypto/phemex-savings',
  LEARN_CRYPTO_HOW_TO_EARN_CRYPTO_QUIZ = '/learn-crypto/how-to-earn-crypto/quiz',
  LEARN_CRYPTO_PERPETUAL_CONTRACTS = '/learn-crypto/perpetual-contracts',
  LEARN_CRYPTO_PERPETUAL_CONTRACTS_HOW_TO_ADJUST_MARGIN = '/learn-crypto/perpetual-contracts/how-to-adjust-margin',
  LEARN_CRYPTO_PERPETUAL_CONTRACTS_HOW_TO_OPEN_POSITION = '/learn-crypto/perpetual-contracts/how-to-open-position',
  LEARN_CRYPTO_PERPETUAL_CONTRACTS_HOW_TO_SET_OR_ADJUST_LEVERAGE = '/learn-crypto/perpetual-contracts/how-to-set-or-adjust-leverage',
  LEARN_CRYPTO_PERPETUAL_CONTRACTS_QUIZ = '/learn-crypto/perpetual-contracts/quiz',
  LEARN_CRYPTO_SPOT_TRADING = '/learn-crypto/spot-trading',
  LEARN_CRYPTO_SPOT_TRADING_INTRODUCTION = '/learn-crypto/spot-trading/introduction',
  LEARN_CRYPTO_SPOT_TRADING_QUIZ = '/learn-crypto/spot-trading/quiz',
  LEARN_CRYPTO_SPOT_TRADING_SPOT_CONDITIONAL_ORDERS = '/learn-crypto/spot-trading/spot-conditional-orders',
  LEARN_CRYPTO_SPOT_TRADING_SPOT_LIMIT_ORDERS = '/learn-crypto/spot-trading/spot-limit-orders',
  LEARN_CRYPTO_SPOT_TRADING_SPOT_MARKET_ORDERS = '/learn-crypto/spot-trading/spot-market-orders',
  LEARN_CRYPTO_SPOT_TRADING_TOKENBOT = '/learn-crypto/spot-trading/tokenbot',
  LEGEND_TRADING_BIND_CARD = '/legend-trading/bind-card',
  LEGEND_TRADING_MANAGE_CARD = '/legend-trading/manage-card',
  LEGEND_TRADING_SYNC_KYC = '/legend-trading/sync-kyc',
  LEGEND_TRADING_WRITE_QUESTIONNAIRE = '/legend-trading/write-questionnaire',
  LEGEND_TRADING_BILLING_ADDRESS = '/legend-trading/billing-address',
  LEGEND_TRADING_CARD_BINDING = '/legend-trading/card-binding',
  LEGEND_TRADING_PAYMENT = '/legend-trading/payment',
  LOGIN = '/login',
  LOGOUT = '/logout',
  REGISTER_LANDING_PAGE = '/lp-crypto-platform',
  LP_CRYPTO_PLATFORM = '/lp-crypto-platform-v2',
  TESLA_GIVEAWAY = '/lp-tesla-giveaway',
  TRADING_DEPOSIT_BONUS = '/lp-trading-deposit-bonus',
  DEPOSIT_DAZZLE_MDX = '/lp-trading-deposit-bonus/mdx',
  MARKETS = '/markets',
  OFFICIAL_VERIFICATION = '/official-verification',
  OTC_LEGEND_TRADING = '/otc/legend-trading',
  OTC_LEGEND_TRADING_HISTORY = '/otc/legend-trading/history',
  PHEMEX_PLATFORM = '/phemex-platform',
  PREMIUM_ARCHIVED = '/premium/archived',
  PROMOTIONS = '/promotions',
  CHECK_ACCOUNT = '/proof-of-reserves',
  REGISTER = '/register',
  REGISTER_VERIFIED = '/register-verified',
  REGISTER_VT1 = '/register-vt1',
  RESET_PASSWORD = '/reset-password',
  RESET_SECURITY = '/reset-security',
  OAUTH_AUTHORIZE = '/oauth',
  OAUTH_AUTHORIZE_LOGIN = '/oauth-login',
  SECURITY = '/security',
  SITEMAP = '/sitemap',
  SPOT_MARGIN_DATA = '/spot/margin-data',
  STUDENT_FOUNDATION = '/student-foundation',
  VIP_PROGRAM = '/vip',
  VIP_PORTAL = '/vip-portal',
  WELCOME = '/welcome',
  YEAR_IN_REVIEW_2022 = '/year-in-review-2022',
  WEB3_SOUL_PASS = '/web3/soulpass',
  WEB3_EQUITY = '/web3/pt',
  WEB3_EQUITY_BENEFITS = '/web3/pt-benefits',
  WEB3_XPT_PREMINING = '/web3/premining',
  WEB3_PHEMEXIA = '/web3/phemexia',
  WEB3_ASSETS_OVERVIEW = '/web3/assets/overview',
  WEB3_STAKE = '/web3/stake/dashboard',
  WEB3_STAKE_BUYBACK_HISTORY = '/web3/stake/buyback-history',
  WEB3_STAKE_STAKE_HISTORY = '/web3/stake/stake-history',
  // PT Trading Reward 延后上线
  // WEB3_PT_MINING = '/web3/trading-rewards',
  WEB3_DUTCH_AUCTION = '/web3/pt-bidding',
  WEB3_AIRDROPS_CRYPTO_MAS = '/web3/airdrops/cryptomas',

  WEB3_LENDING_MARKET = '/web3/lending',
  WEB3_LENDING_DETAILS = '/web3/lending/details-info',
  WEB3_LENDING_DASHBOARD = '/web3/lending/dashboard',

  FIAT_BUY_CRYPTO_BUY_CARD = '/fiat/buy-crypto/buy/card',
  FIAT_BUY_CRYPTO_BUY_CONFIRM = '/fiat/buy-crypto/buy/confirm',
  FIAT_BUY_CRYPTO_BUY_PAYMENT_METHOD = '/fiat/buy-crypto/buy/payment-method',
  FIAT_BUY_CRYPTO_BUY_TRANSAK = '/fiat/buy-crypto/buy/transak',
  FIAT_BUY_CRYPTO_BUY_TRANSAK_RESULT = '/fiat/buy-crypto/buy/transak/result',
  FIAT_BUY_CRYPTO_BUY_RESULT = '/fiat/buy-crypto/buy/result',
  FIAT_BUY_CRYPTO_PLACE_ORDER = '/fiat/buy-crypto/place-order',
  FIAT_BUY_CRYPTO_SELL_CARD = '/fiat/buy-crypto/sell/card',
  FIAT_BUY_CRYPTO_SELL_CONFIRM = '/fiat/buy-crypto/sell/confirm',
  FIAT_BUY_CRYPTO_SELL_PAYMENT_METHOD = '/fiat/buy-crypto/sell/payment-method',
  FIAT_BUY_CRYPTO_SELL_RESULT = '/fiat/buy-crypto/sell/result',

  FIAT_DEPOSIT = '/fiat/deposit',
  FIAT_WITHDRAW = '/fiat/withdraw',
  FIAT_WITHDRAW_ORDER_CONFIRM = '/fiat/withdraw/order-confirm',
  FIAT_DEPOSIT_TRANSFER_INFO = '/fiat/deposit/transfer-info',
  FIAT_WITHDRAW_ORDER_RESULT = '/fiat/withdraw/order-result',
  FIAT_B2C_URL_PAYMENT = '/fiat/b2c-url-payment',
  FIAT_B2C_PAYMENT_RESULT = '/fiat/b2c-payment-result',
  FIAT_ORDER_LIST = '/fiat/order-list',
  STRATEGY_SQUARE = '/trading-bots',
  MOC_STRATEGY_SQUARE = '/moc/trading-bots',
  STRATEGY_DETAIL = '/bot-detail',
  MOC_STRATEGY_DETAIL = '/moc/bot-detail',
  FUTURE_GRIDS_TRADING_CONTEST = '/trading-bots/futuregrids-contest',

  XPT_AIRDROP = '/web3/xpt-infinity',
  CEX_AIRDROP = '/web3/xpt-infinity/cryptic-bounty-airdrop',
  DEX_AIRDROP = '/web3/xpt-infinity/dexclusive-airdrop',
  FTX_AIRDROP = '/web3/xpt-infinity/resurge-rewards-airdrop',

  WEB3_SPACEID = '/web3/airdrops/spaceid',
  WEB3_ETH = '/web3/airdrops/100eth',
  WEB3_1000BTC = '/web3/airdrops/1000btc',
  WEB3_SOCIAL_TRADE_HOME = '/web3/phemex-pulse',
  WEB3_SOCIAL_TRADE_EXPLORE_GROUPS = '/web3/phemex-pulse/explore-groups',
  WEB3_SOCIAL_TRADE_MY_GROUPS = '/web3/phemex-pulse/my-groups',
  WEB3_SOCIAL_TRADE_CAST_DETAIL = '/web3/phemex-pulse/cast',
  WEB3_SOCIAL_TRADE_GROUP_DETAIL = '/web3/phemex-pulse/group',
  WEB3_SOCIAL_TRADE_PT_REWARDS = '/web3/phemex-pulse/pt-rewards',
  WEB3_SOCIAL_TRADE_MOBILE_PT_REWARDS = '/web3/phemex-pulse/m-pt-rewards',
  WEB3_SOCIAL_TRADE_INVITE = '/phemex-pulse/invite-friends-group',
  WEB3_SOCIAL_TRADE_INSCRIPTION_MARKETPLACE = '/web3/phemex-pulse/inscription-marketplace',
  WEB3_SOCIAL_TRADE_INSCRIPTION_MARKETPLACE_h5 = '/web3/phemex-pulse/m-inscription-marketplace',
  WEB3_SOCIAL_TRADE_MY_GROUP_PASS = '/web3/phemex-pulse/my-group-pass',
  WEB3_SOCIAL_TRADE_GROUP_APPLY_MODERATOR_H5 = '/web3/phemex-pulse/group-apply-moderator',
  WEB3_SOCIAL_TRADE_PULSE_DROP = '/web3/phemex-pulse/pulsedrop',
  WEB3_SOCIAL_TRADE_MOBILE_PULSE_DROP = '/web3/phemex-pulse/m-pulsedrop',
  WEB3_SOCIAL_TRADE_PULSE_PROFILE = '/web3/phemex-pulse/profile',
  WEB3_SOCIAL_TRADE_PULSE_PROFILE_EDIT = '/web3/phemex-pulse/edit-profile',
  WEB3_PULSE_PULSE_ACTIVITY_H5 = '/web3/phemex-pulse/m-pulse-activity',
  WEB3_PULSE_HOME = '/web3/phemex-pulse/home',
  PT_PREHEAT_AIRDROP = '/web3/pt-to-the-moon',

  BROKER_INDEX = '/broker',
  BROKER_DASHBOARD = '/broker/dashboard',
  BROKER_TRADERS = '/broker/traders',
  BROKER_COMMISSIONS_CONTRACT = '/broker/contract-commissions',
  BROKER_COMMISSIONS_SPOT = '/broker/spot-commissions',
  BROKER_ASSETS = '/broker/assets',
  BROKER_USER_INFO = '/broker/user-info',

  APIS = '/home-api',
  EMAIL_SUBSCRIBE = '/mail/subscribe',
  REDIRECT = '/redirect',

  CS_EVALUATE = '/cs/evaluate',
  TELEGRAM_APP_AUTH = '/oauth/telegram/app/auth',
}
