import {env} from '@/helper/env';
type Languages = 'en' | 'ru' | 'ko' | 'ja' | 'es' | 'cn' | 'de' | 'fr' | 'tr' | 'pt' | 'vi';

const HOSTNAME: {[K in Languages]?: {prod: string[]; fat?: string[]; ea?: string[]; dev?: string}} = {
  ja: {
    prod: ['phemex.jp', 'pulse.social'],
  },
};

const HostsApiMap: Record<string, Record<string, string>> = Object.keys(HOSTNAME).reduce((p, lang) => {
  const c = HOSTNAME[lang];
  const hosts = c.prod.reduce((p2, c2) => ({...p2, [c2]: 'phemex.com'}), {});
  const fat = c.fat && c.fat.length > 0 ? c.fat : c.prod;
  const fatHosts = fat.reduce((p2, c2) => ({...p2, [`fat.${c2}`]: 'fat.phemex.com', [`fat2.${c2}`]: 'fat2.phemex.com', [`fat3.${c2}`]: 'fat3.phemex.com'}), {});
  const ea = c.ea && c.ea.length > 0 ? c.ea : c.prod;
  const eaHosts = ea.reduce((p2, c2) => ({...p2, [`ea.${c2}`]: 'ea.phemex.com'}), {});
  return {
    ...p,
    [lang]: {
      ...hosts,
      ...eaHosts,
      ...fatHosts,
    },
  };
}, {});

// 不同国家不同域名，但是相同环境访问接口域名相同
// fat.phemex.com => fat.phemex.com
// fat.phemex.jp => fat.phemex.com
export function clearApiHostNationCondition(hostname: string) {
  const apis = Object.values(HostsApiMap).reduce((p, c) => ({...p, ...c}), {});
  return apis[hostname] || hostname;
}

function getDefaultLangByHost(): Languages {
  const hostname = env.isServer ? 'phemex.com' : location.hostname;

  for (const lang in HostsApiMap) {
    if (Object.keys(HostsApiMap[lang]).includes(hostname)) {
      return lang as Languages;
    }
  }
  return 'en';
}

function getDefaultFiatCurrency() {
  const lang = getDefaultLangByHost();
  const map = {
    ja: 'JPY',
    en: 'USD',
  };
  return map[lang] || 'USD';
}

function getSiteByHost() {
  const lang = getDefaultLangByHost();
  const lang2Country = {
    ja: 'JP',
  };
  return lang2Country[lang] || '';
}

const StorageKey = 'localizedRedirect';

// 仅根据域名判断默认语言
export const defaultLangByHost = getDefaultLangByHost();
// 仅根据域名判断默认法币
export const defaultFiatCurrencyByHost = getDefaultFiatCurrency();
// 仅根据域名判断默认站点标记
export const defaultSiteByHost = getSiteByHost();
// 是否是独立站点
export const isLocalizedSite = defaultLangByHost !== 'en';
// 独立站点第一次访问时需要重定向到对应语言
export const needFirstTimeRedirect = isLocalizedSite && (env.isServer ? false : !localStorage.getItem(StorageKey));
export const updateFirstTimeRedirect = (val = 'true') => {
  localStorage.setItem(StorageKey, val);
};
// 剔除独立站不支持的语言
export const fixInvalidLang = function (lang) {
  if (isLocalizedSite && ![defaultLangByHost, 'en'].includes(lang)) {
    return defaultLangByHost;
  }
  return lang;
};
